import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig/FirebaseConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribeUserData = null;

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDocRef = db.collection("users").doc(user.uid);
          const sessionRef = db.collection("sessions").doc(user.email);

          const userSession = await sessionRef.get();
          if (userSession.exists && userSession.data().active) {
            unsubscribeUserData = userDocRef.onSnapshot((doc) => {
              if (doc.exists) {
                setUserData(doc.data());
                setUser(user);
              } else {
                handleLogout();
              }
            });
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error("Error in onAuthStateChanged:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setUser(null);
        setUserData(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
      if (unsubscribeUserData) unsubscribeUserData();
    };
  }, []);

  const handleLogout = async () => {
    if (user) {
      try {
        // Ensure session deactivation is atomic
        await db.runTransaction(async (transaction) => {
          const sessionRef = db.collection("sessions").doc(user.email);
          const sessionDoc = await transaction.get(sessionRef);

          if (sessionDoc.exists && sessionDoc.data().active) {
            transaction.update(sessionRef, { active: false });
          }
        });

        await auth.signOut();
        setUser(null);
        setUserData(null);
      } catch (error) {
        console.error("Logout error:", error.message);
      }
    }
  };

  const login = async (email, password) => {
    const normalizedEmail = email.trim().toLowerCase();

    setLoading(true);
    try {
      const sessionRef = db.collection("sessions").doc(normalizedEmail);

      await db.runTransaction(async (transaction) => {
        const sessionDoc = await transaction.get(sessionRef);

        if (sessionDoc.exists && sessionDoc.data().active) {
          const confirmLogin = window.confirm(
            "Warning! Multiple device login detected. Do you want to proceed?"
          );

          if (!confirmLogin) {
            // throw new Error("Login terminated by user.");
            throw {
              code: "auth/login-terminated",
              message: "Login terminated by user.",
            };
          }
        }

        transaction.set(sessionRef, {
          active: true,
          device: navigator.userAgent,
          lastLogin: new Date(),
        });
      });

      const userCredential = await auth.signInWithEmailAndPassword(
        normalizedEmail,
        password
      );
      setUser(userCredential.user);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signup = async (email, password) => {
    const normalizedEmail = email.trim().toLowerCase();
    setLoading(true);
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        normalizedEmail,
        password
      );
      const { user } = userCredential;

      await db.collection("users").doc(user.uid).set({
        normalizedEmail,
        name: "Business Name",
        number: 9876543210,
        uid: user.uid,
        maxItems: 96,
        userType: "user",
        address: "Business Address",
        tandc: "Terms and conditions",
        maxUpdates: 5,
        createdAt: new Date(),
      });

      const sessionRef = db.collection("sessions").doc(normalizedEmail);
      await db.runTransaction(async (transaction) => {
        transaction.set(sessionRef, {
          active: true,
          device: navigator.userAgent,
          lastLogin: new Date(),
        });
      });

      setUser(user);
    } catch (error) {
      console.error("Signup error:", error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, userData, login, signup, logout: handleLogout, loading }}
    >
      {loading && (
        <div className="flone-preloader-wrapper">
          <div className="flone-preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
